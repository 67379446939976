<template>
  <div
    class="text-center"
    :class="{
      'my-20 md:my-30': data?.style === 'White',
      'bg-purple-700 py-20 text-white md:py-30': data?.style === 'Purple'
    }"
  >
    <Heading v-if="data.title" :size="3" generic class="mx-auto mb-10 md:mb-28"
      ><div v-html="data.title"></div
    ></Heading>
    <div class="grid-default px-page mx-auto max-w-xl gap-7 md:max-w-none">
      <div
        v-for="(item, index) in data?.benefits"
        :key="index"
        :class="{
          'col-span-12 md:col-span-4': data?.benefits.length === 3,
          'col-span-6 md:col-span-3': data?.benefits.length === 4
        }"
      >
        <Image
          class="mx-auto h-auto w-18 md:w-30"
          loading="lazy"
          :src="item.media?.url as string"
          :width="item.media?.width"
          :height="item.media?.height"
          :alt="item.media?.alt as string"
        />
        <div
          class="mt-4"
          :class="{
            'mb-2 text-heading-18-medium md:mb-4 md:mt-6 md:text-heading-32-medium':
              data?.benefits.length === 3,
            'text-body-20-regular-mobile md:mt-9 md:text-body-32-regular':
              data?.benefits.length === 4
          }"
        >
          {{ item.title }}
        </div>
        <div
          v-if="item.body"
          class="mx-auto max-w-xs text-body-15-regular-mobile md:max-w-md md:text-body-22-regular"
          v-html="item.body"
        ></div>
      </div>
    </div>

    <Button
      v-if="Array.isArray(data.button) && data.button.length"
      :label="data.button[0].label"
      :to="
        data.button[0].internalLink !== null
          ? localePath(`/${data?.button[0].internalLink!.slug}`)
          : data.button[0].externalLink
      "
      variant="solid"
      size="lg"
      class="mx-auto mt-10 md:mt-24"
    />
  </div>
</template>

<script setup lang="ts">
import { SectionBenefitsListRecord } from '~/types/generated'

const localePath = useLocalePath()

defineProps({
  data: {
    type: Object as PropType<SectionBenefitsListRecord>,
    default: () => {}
  }
})
</script>
